.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

input {
  width: inherit !important;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.shake {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.circle {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 8px;
  background: #037FFF;
  color: #ffffff;
  text-align: center;
}

iframe {
  transform: scale(var(--scale-factor));
  width: calc(1/var(--scale-factor) * 100%);
  height: calc(1/var(--scale-factor) * 100%);
}

.iframe-container {
  --scale-factor: 0.5;
  overflow: hidden;
  /* props below are not mandatory */
  width: 450px;
  height: 250px;
  border: 1px solid black;
}


.slider {
  position: relative;
  width: 200px;
  height: 200px;
  transform-style: preserve-3d;
  animation: rotate 1s linear;
}

@keyframes rotate {
  0% {
      transform: perspective(1000px) rotateY(0deg);
  }

  100% {
      transform: perspective(1000px) rotateY(360deg);
  }
}

.slider span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: center;
  transform-style: preserve-3d;
  transform:rotateY(calc(var(--i)*45deg)) translateZ(350px);
}

.slider span img{
  position: absolute;
  top: 10;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;  
  object-fit: cover;
  transition: 3s;
}


@media screen and (max-width:600px) {
  .slider{
      width: 50px;
      height: 50px;
  }
  .slider span{
  transform:rotateY(calc(var(--i)*45deg)) translateZ(120px);
  }
}